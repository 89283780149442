import React from 'react'
import '../assets/scss/style.css';
import BlogGridView from "../components/blog/BlogGridView";
import SEO from "../common/SEO";
import {Helmet} from "react-helmet";

const Blog = () => {
    return (
        <>
            <Helmet>
                <title>Router Architects Blogs - Wifi Digital Services</title>
                <meta name="description" content="Experts in RDK/RDKB, opensync, openWrt, Easy Mesh, Qualcomm WiFi SON, TR181, CPE Telemetry, BLE, WiFi Analytics" />
                <link rel="icon" type="image/png" href="/images/logo/logo-favicon.png" sizes="16x16" />
            </Helmet>
            {/* <SEO title="blogs" /> */}
            <BlogGridView/>
        </>
    )
}

export default Blog;
